import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { AxiosResponse } from 'axios';
import bg from '../common/assets/dw-bg.png';
import userIcon from '../common/assets/Usericon.png';
import passwordIcon from '../common/assets/PWicon.png';
import dwlogo from '../common/assets/DWlogo.png';
import { BsGlobe } from 'react-icons/bs';

import bgW from '../common/assets/dowinn-bg.webp';

import { langVar } from '../common/apollo';
import { SESSIONSTORAGE_LANGUAGE } from '../common/constants';
import { LOGIN_MUTATION } from '../common/api/mutations/login_mutation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { LazyBackgroundImg } from '../components/lazyBackgroundImg';
import { Spin } from 'antd';

interface IloginForm {
  user_id: string;
  password: string;
}

export const Login = () => {
  const { t, i18n } = useTranslation(['page']);
  const [requested, SetRequested] = useState(true);
  const [loginId, setLoginId] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [selectedLang, setSelectedLang] = useState('');
  const [deviceUsed, setDeviceUsed] = useState(0);
  const [isUserInfoValid, setIsUserInfoValid] = useState(false);

  const [isBtnHover, setIsBtnHover] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IloginForm>({
    mode: 'onChange',
  });

  const loginIdOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetRequested(false);
    setIsValid(true);
    setLoginId(e.target.value);
  };

  useEffect(() => {
    setDeviceUsed(isMobileOnly ? 1 : 0);
  }, [isMobileOnly]);

  const onSubmit = async () => {
    const { password } = getValues();
    const confirmLang =
      selectedLang === 'English'
        ? 'en'
        : selectedLang === '한국어'
        ? 'ko'
        : 'zh';
    const data = {
      disp_id: loginId,
      passwd: password,
      isMobile: deviceUsed,
      languageCode: confirmLang,
    };
    await LOGIN_MUTATION(data)
      .then((res: AxiosResponse | any) => {
        SetRequested(true);
        if (res.data) {
          if (res.data.data.message === 'ok') {
            setIsUserInfoValid(true);
            window.location.replace(res.data.data.slotData.GameUrl);
          } else {
            alert('Invalid ID or Password');
          }
        }
        if (res.response) {
          if (res.response.status === 401) {
            alert('Request Failed');
          }
        }
        if (res.request) {
          if (res.request.response === '') {
            alert('Request Failed');
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Request Failed');
        SetRequested(true);
        setIsValid(false);
      });
  };

  const onChangeLang = (e: { target: { value: any } }) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
    setSelectedLang(newLang);
    sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, newLang);
  };

  const languageArr = [
    { key: 'English', value: 'English', hidden: false },
    { key: '한국어', value: '한국어', hidden: false },
    { key: '中文', value: '中文', hidden: false },
  ];

  useEffect(() => {
    const browserLanguage = window.navigator.language;
    let newLanguage;
    if (browserLanguage.startsWith('ko')) {
      newLanguage = '한국어';
    } else if (browserLanguage.startsWith('zh')) {
      newLanguage = '中文';
    } else {
      newLanguage = 'English';
    }
    i18n.changeLanguage(newLanguage);
    setSelectedLang(newLanguage);
    sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, newLanguage);
  }, []);

  useEffect(() => {
    sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, 'English');
  }, []);

  const filteredLanguageArr = languageArr.map((language) => {
    if (language.value === selectedLang) {
      return { ...language, hidden: true };
    }
    return language;
  });

  return (
    <div className='h-screen w-full min-h-fit'>
      <div
        className='fixed bg-no-repeat bg-cover bg-center top-0 right-0 bottom-0 left-0'
        style={{
          backgroundImage: `url(${bg})`,
          backgroundColor: 'black',
        }}
      />

      <LazyBackgroundImg
        src={bgW}
        className='bg-no-repeat bg-cover bg-center'
        style={{ width: '100%', height: '100%' }}
      />
      <Helmet>
        <title>DW SLOTS</title>
      </Helmet>
      <div className='fixed top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center'>
        <div className='w-full flex flex-col justify-center items-center '>
          <div
            className='mt-5 flex flex-col items-center bg-no-repeat sm:rounded-none md:rounded-none xl:rounded-3xl bg-center m-5 px-8 opacity-95'
            style={{
              backgroundColor: '#252525',
            }}
          >
            <div className='2xl:py-4 sm:py-4 md:py-4'>
              <div className='flex justify-end'>
                <div className='rounded-full w-24 xl:w-22 mt-5 sm:mt-0 md:mt-5 xl:mt-3 border border-white py-1 sm:py-0.5'>
                  <div className='flex justify-end mr-1'>
                    <div className='items-center pl-4 text-white flex border-transparent'>
                      <BsGlobe
                        style={{
                          color: '#D9BC78',
                        }}
                      />
                    </div>
                    <select
                      className='rounded-sm cursor-pointer text-center text-sm w-32 text-white border-none outline-none'
                      value={selectedLang}
                      onChange={onChangeLang}
                      style={{
                        backgroundColor: '#252525',
                      }}
                    >
                      {filteredLanguageArr.map((language) => (
                        <option
                          key={language.value}
                          value={language.value}
                          hidden={language.hidden}
                        >
                          {t(language.key)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='mt-5 flex flex-col items-center bg-no-repeat rounded-3xl bg-center m-1 px-2 opacity-95'>
                <img
                  src={dwlogo}
                  alt={dwlogo}
                  className='w-auto h-32 md:w-auto sm:h-36 md:h-36 xl:h-56'
                />
              </div>
              <div className='flex flex-row justify-center'>
                <div className='xl:px-10 lg:px-10'>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='grid gap-5 w-full mb-10'
                    autoComplete='off'
                  >
                    <input
                      type='text'
                      name='username'
                      className='w-0 h-0 border-0 block'
                    />
                    <input type='password' className='w-0 h-0 border-0 block' />
                    <div className='flex border-solid border border-gray-400 border-t-0 border-r-0 border-l-0 border-b'>
                      <img className='h-6 mt-3.5' src={String(userIcon)} />
                      <input
                        {...register('user_id')}
                        autoComplete='off'
                        required
                        placeholder={t('Account')}
                        pattern='^[a-zA-Z0-9]*$'
                        className='px-28 pl-3 block w-full input bg-transparent border-none text-white placeholder:text-gray-400 placeholder:italic focus:placeholder:opacity-0'
                        onChange={loginIdOnChange}
                        autoFocus
                      ></input>
                    </div>
                    <div className='mt-2 flex border-solid border border-gray-400 border-t-0 border-r-0 border-l-0 border-b'>
                      <img className='h-6 mt-3.5' src={String(passwordIcon)} />
                      <input
                        {...register('password', {
                          required: 'password is required',
                        })}
                        autoComplete='off'
                        required
                        type='password'
                        pattern='^[a-zA-Z\\d`~!@#$%^&*()-_=+]*$'
                        minLength={1}
                        placeholder={t('Password')}
                        className='block w-full input bg-transparent border-none text-white placeholder:text-gray-400 placeholder:italic focus:placeholder:opacity-0'
                      ></input>
                    </div>
                    <div className='mt-6 sm:mb-10 flex flex-col items-center'>
                      <div
                        className={`rounded-xl cursor-pointer relative ${
                          isSubmitting && 'pointer-events-none'
                        }`}
                        onMouseOver={() => setIsBtnHover(true)}
                        onMouseLeave={() => setIsBtnHover(false)}
                        style={{
                          backgroundColor: isBtnHover ? '#9D7D51' : '#c59c62',
                          width: '100%',
                        }}
                      >
                        {isSubmitting || isUserInfoValid ? (
                          <div className='absolute w-full h-full flex items-center justify-center pt-1 bg-black bg-opacity-60 rounded-xl'>
                            <Spin className='spinLogin' />
                          </div>
                        ) : undefined}

                        <button
                          role='button'
                          type='submit'
                          disabled={isSubmitting || isUserInfoValid}
                          className={`text-white w-full text-3xl px-6 py-1.5 sm:py-3 focus:outline-none font-bold sm:font-bold border-none whitespace-nowrap`}
                        >
                          {t('Login')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='mt-3 md:mt-3 2xl:mt-4 mb-5 text-white text-lg'>
              Version 0.7.1
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
