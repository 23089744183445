import React, { useEffect, useState } from 'react';
import bgD from '../common/assets/deskview.png';
import bgM from '../common/assets/mobview.png';
import { isMobileOnly } from 'react-device-detect';

export const GeoBlock = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const handleScreenResize = (
      event: MediaQueryListEvent | MediaQueryList
    ) => {
      if (event.matches) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };
    const mediaQuery = window.matchMedia('(max-width: 640px)');
    handleScreenResize(mediaQuery);
    mediaQuery.addEventListener('change', handleScreenResize);
    return () => {
      mediaQuery.removeEventListener('change', handleScreenResize);
    };
  }, [mobileView]);
  return (
    <div
      className='fixed top-0 right-0 bottom-0 left-0 h-screen w-full min-h-fit'
      style={{ background: '#1a1a1a' }}
    >
      <img
        className='absolute w-full h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        src={mobileView ? bgM : bgD}
        alt={String(mobileView ? bgM : bgD)}
      />
    </div>
  );
};
