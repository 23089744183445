import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Login } from '../pages/login';
import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from '../pages/404';
import { GeoBlock } from '../pages/geoblock';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/geoblock' element={<GeoBlock />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
