import { ApolloClient, makeVar, InMemoryCache } from '@apollo/client';
import { SESSIONSTORAGE_LANGUAGE } from './constants';
import * as dotenv from 'dotenv';
import { RestLink } from 'apollo-link-rest';

const mySelectedLang = sessionStorage.getItem(SESSIONSTORAGE_LANGUAGE);

// Authority state management
export const langVar = makeVar(mySelectedLang);

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_URL?.slice(
    0,
    process.env.REACT_APP_URL.length - 1
  )}`,
  customFetch: fetch,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${process.env.REACT_CLIENT_URL}`,
    'Access-Control-Allow-Credentials': 'true',
  },
  credentials: 'include',
});

export const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          langVar: {
            read() {
              return langVar();
            },
          },
        },
      },
    },
  }),
});
