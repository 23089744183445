import axios, { AxiosInstance } from 'axios';
import { appConfig } from '../appConfig';

const env = 'development';
const { apiUrl, gameLoginUrl, gameNewAuth } = appConfig[env];

export const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'X-Auth-Geo': gameNewAuth,
  },
});

export const gameLoginClient: AxiosInstance = axios.create({
  baseURL: gameLoginUrl,
  headers: {
    'X-Auth-Geo': gameNewAuth,
  },
});

// apiClient.defaults.headers.common['X-CSRF-TOKEN']

// apiClient.interceptors.request.use(
//   (response) => {
//     console.log(response);
//     return response;
//   },
//   (error) => {
//     console.log('REQUEST ERROR OCCURED');
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

// apiClient.interceptors.response.use(
//   (response) => {
//     console.log(response);
//     return response;
//   },
//   (error) => {
//     console.log('RESPONSE ERROR OCCURED');
//     if (error.response.data.message === 'Unauthorized') {
//       useReactiveVar(isLoggedInVar);
//       isLoggedInVar(false);
//     }
//     return Promise.reject(error);
//   }
// );

apiClient.defaults.withCredentials = true;
apiClient.defaults.xsrfCookieName = 'Cookie';
apiClient.defaults.headers.common['Cookie'];

gameLoginClient.defaults.withCredentials = true;
gameLoginClient.defaults.xsrfCookieName = 'Cookie';
gameLoginClient.defaults.headers.common['Cookie'];
