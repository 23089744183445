import { gql } from '@apollo/client';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiClient, gameLoginClient } from '../apiConfig';

// export const LOGIN_MUTATION = gql`
//   fragment LoginPayload on REST {
//     user_id: String
//     password: String
//   }
//   mutation LoginMutation($input: LoginPayload!) {
//     loginMe(input: $input) @rest(type: "data", path: "/login", method: "POST") {
//       data
//     }
//   }
// `;

// FOR LOCAL SERVER API
// export interface ILoginPayload {
//   user_id: string;
//   password: string;
// }

// export const LOGIN_MUTATION = async (data: ILoginPayload) => {
//   try {
//     const payload = data;
//     const response: AxiosResponse = await apiClient.post('login', payload);
//     return response;
//   } catch (err) {
//     return err;
//   }
// };

// FOR ACTUAL SERVER API

export interface ILoginPayload {
  disp_id: string;
  passwd: string;
  isMobile: number;
  languageCode: string;
}

export const LOGIN_MUTATION = async (data: ILoginPayload) => {
  try {
    const payload = data;
    const response = await gameLoginClient
      .post('slot_add_user', payload)
      .then((res: AxiosResponse) => {
        return res;
      })
      .catch((err: AxiosError) => {
        return err;
      });
    return response;
  } catch (err) {
    return err;
  }
};

// export const LOGIN_MUTATION = async (data: ILoginPayload) => {
//   const url = process.env.REACT_APP_GAME_LOGIN_URL + 'slot_add_user';
//   const options = {
//     method: 'post',
//     url: url,
//     data: data,
//     headers: {
//       'X-Auth-Id': process.env.REACT_APP_GAME_LOGIN_AUTH_ID,
//       'X-Auth-Key': process.env.REACT_APP_GAME_LOGIN_AUTH_KEY,
//     },
//   };

//   return await axios(options)
//     .then((res: AxiosResponse) => {
//       return res;
//     })
//     .catch((err: AxiosError) => {
//       return err;
//     });
// };
