import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyBackgroundImageProps {
  src: string;
  className?: string;
  style?: React.CSSProperties;
}

export const LazyBackgroundImg = ({
  src,
  className,
  style,
}: LazyBackgroundImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // Load image only once when it comes into view
    rootMargin: '200px 0px', // Adjust rootMargin as needed based on your design
  });

  useEffect(() => {
    if (inView && !imageLoaded) {
      const imageLoader = new Image();
      imageLoader.src = src;
      imageLoader.onload = () => setImageLoaded(true);
    }
  }, [inView, imageLoaded, src]);

  const backgroundImageStyle: React.CSSProperties = imageLoaded
    ? { backgroundImage: `url(${src})` }
    : { backgroundColor: 'black' };

  return (
    <div
      ref={ref}
      className={className}
      style={{
        ...style,
        ...backgroundImageStyle,
      }}
    />
  );
};
